//import {RequestDetails} from 'deep-chat/dist/types/interceptors';
import { DeepChat } from 'deep-chat-react';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useEffect, useState, useMemo, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactDialogBox } from 'react-js-dialog-box'
import './chatai.css'
import Document from '../Document-Upload/document';
import UploadProgress from '../Component/Upload-Progress/upload-progress';
import config from '../../../config.json'

// const Logo = require("../../assets/logo.png");
const uploadDoc = require("../../../images/assets/upload.png");
const AILOGOGIF = require("../../../images/boost-ai.gif");
const BILOGO = require("../../../images/assets/bi.png");
const USERIC = require("../../../images/assets/profile_dp.png");
function ChatAI(props) {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false) // Open Document Dialogbox

  const [uploadDocument, setUploadDocument] = useState(false) // Manage Upload Document

  const [openUploadProgress, setOpenUploadProgress] = useState(false) // Manage with Upload Progress
  const [openUploadSuccess, setOpenUploadSuccess] = useState(false) // Manage with Upload Success

  const [dialogTitle, setDialogTitle] = useState('Upload Document')
  const [dialogHeight, setDialogHeight] = useState('auto')
  const [getToken, setToken] = useState('')
  const [messageHistory, setMessageHistory] = useState(() => {
    const storedMessages = localStorage.getItem('messagesAI');
    return storedMessages ? JSON.parse(storedMessages) : [];
  });
  // Upload Document 
  const [showProgressScreen, setShowProgressScreen] = useState(false)
  const [getDocType, setSetDocType] = useState('')
  const [completed, setCompleted] = useState(0);
  var isOpenDialog = true;
  var newMessages = []
  //  var messageHistory = []


  useEffect(() => {

    if (!user?.access_token) {
      navigate('/')
    }

    AsyncStorage.getItem("token").then((value) => {
      setToken(value)
    })
      .then(res => {
        console.log(res)
      });

    if (localStorage.getItem('messagesAI') !== null) {

      const storedObjectString = localStorage.getItem('messagesAI')
      const storedObject = storedObjectString ? JSON.parse(storedObjectString) : { key: 'value' };

      setMessageHistory(storedObject);
    }



  }, [])

  const { user } = useSelector(state => {
    return state.authReducer;
  });


  const documentUpload = () => {

    if (isOpenDialog) {
      setOpenDocumentDialog(true)
      setUploadDocument(true)
      setShowProgressScreen(false)
    }

  }

  const closeDialog = () => {
    setOpenDocumentDialog(false)
    setDialogTitle('Upload Documents')
    setDialogHeight('auto')
  }


  return (
    <>

      <div className="components">
        <DeepChat

          messageStyles={{
            "default": {
              "shared": {
                "bubble": {
                  "maxWidth": "100%", "backgroundColor": "unset", "marginTop": "10px", "marginBottom": "10px",
                  'paddingBottom': '5px'
                },

              },
              "user": {
                "outerContainer": {
                  "marginLeft": "3%", "maxWidth": "85%", "backgroundColor": "#FFF", "marginTop": "16px", "marginBottom": "16px", "border": "1px solid #D1D1D1", "borderRadius": "12px",
                  'paddingBottom': '5px'
                },
                "bubble": {
                  "maxWidth": "85%", "marginLeft": "0px", "color": "black", "fontWeight": "500"
                },
              },
              "ai": {
                "bubble": {
                  "marginLeft": "0%", "width": "85%", "backgroundColor": "#F8F5FF",

                },
                "outerContainer": {
                  "marginLeft": "3%", "maxWidth": "85%", "backgroundColor": "#F8F5FF", "borderRadius": "12px",
                  'paddingBottom': '5px'
                }
              },
            },
            "error": {
              "outerContainer": {
                'width': '85%',
                "text-align": 'end', // Align text to the right
                "color": "white",
                "fontSize": "15px",
                'alignItems': 'end', 'alignContent': 'end'
              },
              "innerContainer": { 'width': '100%', 'textAlign': 'end', 'paddingLeft': '3%' },
              "bubble": { "text-align": 'end', 'marginLeft': '0%', "maxWidth": "85%", "marginLeft": "2%", }
            },

          }}

          avatars={
            {
              "ai": {
                "src": `${BILOGO}`, "styles": {
                  "position": "left", "avatar": {
                    'padding': '0px', 'paddingBottom': '0px',
                    'width': '36px', 'height': '36px'
                  }
                }
              },
              "user": {
                "src": `${USERIC}`, "styles": {
                  "position": "left", "avatar": {
                    'padding': '0px', 'paddingBottom': '0px',
                    'width': '36px', 'height': '36px'
                  }
                }
              },
            }
          }

          // style={{ borderRadius: '10px', width: '50%', height: '700px' }}
          // style={{ borderRadius: '10px', width: 'calc(40vw)', height: 'calc(100vh - 280px)', paddingTop: '4px' }}
          style={{ borderRadius: '10px', width: '880px', height: '540px', paddingTop: '4px', "displayLoadingBubble": false, }}
          // style={{ borderRadius: '10px', paddingTop: '10px' }}
          textInput={{
            "styles": {
              "container": {
                "width": "85%",
                "margin": "0",
                "border": "1px solid #6382f3",
                "borderTop": "1px solid #6382f3",
                "borderRadius": "5px",
                "boxShadow": "unset",
                "marginBottom": "12px",
                "paddingTop": "2px",
                "paddingBottom": "5px",
                "paddingLeft": "2px",
                "paddingRight": "2px"
              },
              "text": {
                "fontSize": "1.05em",
                // "paddingTop": "11px",
                // "paddingBottom": "13px",
                // "paddingLeft": "12px",
                // "paddingRight": "2.4em"
              }
            },
            "placeholder": { "text": "Ask your questions here", "style": { "color": "#8b8b8b" } }
          }}

          submitButtonStyles={{
            "submit": {
              "container": {
                "default": { "backgroundColor": "#0000", "width": "1.45em", "bottom": "0.9rem", "color": "#2553F6 !important" }
              },
              "svg": {
                "styles": {
                  "default": {
                    "width": "-0.79em !important",
                    "filter":
                      "brightness(39) saturate(100%) invert(15%) sepia(42%) saturate(6830%) hue-rotate(218deg) brightness(100%) contrast(100%)"
                  },

                }
              },
            },
            "alwaysEnabled": true,
            "position": "outside-right",
          }}

          // submitButtonStyles={{
          //   "disabled": {
          //     "container": {
          //       "default": { "backgroundColor": "#0000", "width": "1.4em", "bottom": "0.9rem" }
          //     }
          //   },
          //   "position": "outside-right"
          // }}

          introMessage={{
            text: 'Hello! Please ask me any questions related your financial information',
            role: 'ai'
          }}

          request={{
            // url: 'http://3.111.141.10/api/v1/bot/chat',
            url: config.genAIPath + 'api/v1/bot/chat',
            headers: { Authorization: `Bearer ${getToken}` }
          }}

          requestBodyLimits={{ maxMessages: 1 }}
          requestInterceptor={(RequestDetails) => {
            var requestBody = {
              "text": RequestDetails.body.messages[0].text, "role": "user",
            }
            isOpenDialog = false;


            if (messageHistory !== null && messageHistory.length > 0) {
              for (var i = 0; i < messageHistory.length; i++) {
                newMessages.push(messageHistory[i])
              }
            }
            newMessages.push(requestBody)
            return RequestDetails;
          }}
          responseInterceptor={(response) => {
            var responseBody = {
              "text": response.text, "role": "ai",
            }

            newMessages.push(responseBody)
            localStorage.setItem("messagesAI", JSON.stringify(newMessages))
            isOpenDialog = true;
            return response;
          }}
          initialMessages={messageHistory}
        />

        <div className='upload-document' onClick={() => { documentUpload() }}>
          <img src={uploadDoc} alt='upload' />
        </div>

        {openDocumentDialog && (
          <div className='document-dialog'>
            <ReactDialogBox
              closeBox={closeDialog}
              headerBackgroundColor='white'
              headerTextColor='black'
              headerHeight='65px'
              closeButtonColor='black'
              bodyBackgroundColor='white'
              bodyTextColor='black'
              bodyHeight={dialogHeight}
              headerText={dialogTitle}
              borderRadius='8px'
            >

              {uploadDocument &&
                <Document closeDialog={closeDialog}
                  setDialogTitle={setDialogTitle}
                  setOpenDocumentDialog={setOpenDocumentDialog}
                  setDialogHeight={setDialogHeight}
                  setMessageHistory={setMessageHistory}
                  setSetDocType={setSetDocType}


                />
              }
            </ReactDialogBox></div>)}

        {showProgressScreen && (
          <div className='upload-progress'>
            <ReactDialogBox
              headerBackgroundColor='white'
              headerTextColor='white'
              headerHeight='0'
              closeButtonColor='white'
              bodyBackgroundColor='white'
              bodyTextColor='black'
              bodyHeight="400px"
              borderRadius='8px'
            >
              <UploadProgress closeDialog={props.closeDialog} docProgress={completed} docType={getDocType} setShowProgressScreen={setShowProgressScreen} />

            </ReactDialogBox></div>)}


      </div>
    </>
  );
}

export default ChatAI;
