import React, { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const SavingAnalysis = (props) => {

    let income = [];
    let expense = [];
    let categorie = [];
    let incomeVsExpenseData = props.incomeVsExpenseData ? props.incomeVsExpenseData : null

    useEffect(() => {
        {
            if (incomeVsExpenseData && incomeVsExpenseData.length > 0) {
                // Fetch the last 6 data points if more than 6, otherwise fetch all
                const lastSixData = incomeVsExpenseData.slice(-6);

                lastSixData.forEach((incomeData) => {

                    income.push(incomeData.moneyIn);
                    expense.push(incomeData.moneyOut);
                    categorie.push(incomeData.month);
                });
            }
        }
    })

    //  const averageBalance = incomeVsExpenseData && incomeVsExpenseData?.data && incomeVsExpenseData?.data[0] && incomeVsExpenseData?.data[0] != null ? incomeVsExpenseData.data[0].amount : null

    const barChart = {
        series: [{
            type: 'column',
            name: 'Money in',
            data: income
        }, {
            type: 'column',
            name: 'Money out',
            data: expense
        }
        ],
        options: {
            chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                    show: false
                }
            },
            colors: ['#4580F6', '#F99176'],
            plotOptions: {
                bar: {
                    borderRadius: 4,
                    horizontal: false,
                    columnWidth: '40%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: categorie,
            },
            fill: {
                opacity: 1,
                type: "gradient",
                gradient: {
                    type: "vertical",
                    shadeIntensity: 1,
                    opacityFrom: 1,
                    opacityTo: 1,
                    colorStops: [
                        [
                            {
                                offset: 0,
                                color: '#61DFFB',
                                opacity: 1
                            },
                            {
                                offset: 100,
                                color: '#3549F3',
                                opacity: 1
                            }
                        ],
                        [
                            {
                                offset: 0,
                                color: '#FECE92',
                                opacity: 1
                            },
                            {
                                offset: 100,
                                color: '#F45D5F',
                                opacity: 1
                            }
                        ]
                    ]
                },
            },
            // annotations: {
            //     yaxis: [
            //         {
            //             y: averageBalance,
            //             borderColor: '#000000',
            //             strokeDashArray: 5,
            //             label: {
            //                 borderColor: '#000000',
            //                 style: {
            //                     color: '#000000',
            //                     background: '#000000'
            //                 },
            //             }
            //         }
            //     ]
            // },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return "₹ " + val.toLocaleString()
                    }
                }
            },
            legend: {
                labels: {
                    useSeriesColors: false,
                },
            },
        }
    };

    return (
        <>
            {incomeVsExpenseData && incomeVsExpenseData.length > 0 != null &&
                <div className='account-details-box'>
                    <div className="accordion incomevsexpense" id="accordionFlushExample">
                        <p className="acc-info-text p-0 m-0 mb-2">CASHFLOW <i className="bi bi-info-circle boost-info-icon me-1"></i></p>
                        <p className="font-size-14 fw-normal p-0 m-0 mb-2">Money-in To Money-out </p>
                        <div className='dividers w-100'></div>
                        <ReactApexChart options={barChart.options} series={barChart.series} type="bar"
                            height={425} />
                        <p className="chart-bottom-text">MONTHLY mONEY IN TO MONEY OUT CHART</p>

                    </div>
                </div>
            }
        </>
    )
}

export default SavingAnalysis