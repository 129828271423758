import '../../../App.css'
import './Scroe-card.css'
import { useEffect, useState } from 'react';
import { creditReport, fetchFetchExperian, refreshToken, updateExperianConsent } from '../../../redux/actions/dashboard_action';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import getUserData from '../../../Common/helper';
import Footer from '../../landing-page/Footer/Footer';
import BankStatment from '../Bank/bankstatement';
import ProgressBar from "@ramonak/react-progress-bar";
import { ReactDialogBox } from 'react-js-dialog-box';
import { authCode, emailVerification, emailParseData } from '../../../redux/actions/auth_action';
import Insights from './components/insihght/insights-dilog';
import ScoreDetails from './components/score-details/score-range-dailog';
import ErrorMessage from '../error-message/error-message';
import { useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { dispatchAction } from '../../../redux/actions/common_action';
import { SELECTED_BUREAU } from '../../../redux/constants';
import Loader from '../../loader/loader';
import { enhanceFetchCredit } from '../../../redux/actions/dashboard_action';
import { v4 as uuidv4 } from 'uuid';
import Select, { components } from 'react-select';
import { customStyles } from './components/custom-style-component-bureau'
import { Dropdown, Modal } from 'bootstrap';
import ExperianModal from '../../Component/experian-dialog/experian-model';

const AxisBank = require("../../../images/Bank/Bank.png");
const Score = require("../../../images/assets/Chart.png");
const PaymentsIcon = require("../../../images/assets/Time Machine.png");
const LimitIcon = require("../../../images/assets/Money Box.png");
const AgeIcon = require("../../../images/assets/Schedule.png");
const AccountIcon = require("../../../images/assets/Merchant Account.png");
const EnquiriesIcon = require("../../../images/assets/Query.png");
const ICArrowUp = require("../../../images/assets/ic-arrow-up.png");
const ExperianLogo = require("../../../images/Bank/experian.png");
const CibillLogo = require("../../../images/Bank/cibil.png");
const CrifLogo = require("../../../images/Bank/crif.png");
const EquifaxLogo = require("../../../images/Bank/equifax.png");

const paymentDoneIcon = require("../../../images/assets/paid.png");
const thumpIcon = require("../../../images/assets/thump.png");
const days89Icon = require("../../../images/assets/days89.png");
const days90Icon = require("../../../images/assets/days90.png");
const filterIcon = require("../../../images/assets/filter.png");
const delayIcon = require("../../../images/assets/medium_risk.png");
const overdueIcon = require("../../../images/assets/high_risk.png");
const noDpdIcon = require("../../../images/assets/no_dpd.png");

function ScoreCards(props) {

    const [creditReportData, setCreditReportData] = useState();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [userData, setUserData] = useState();
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [isOpenDialogS, setIsOpenDialogS] = useState(false);
    const [dialogHeight, setDialogHeight] = useState('auto')
    const [selectedBureau, setSelectedBureau] = useState('');
    const [bureauList, setBureauList] = useState([]);
    const [showError, setShowError] = useState(false);
    const [isVerified, setIsVerified] = useState(false);
    const [analysisReportData, setAnalysisReportData] = useState();
    const [insightsDialog, setInsightsDialog] = useState(false);
    const [selectedAnalysisName, setSelectedAnalysisName] = useState();
    const [emailErrorMessage, setEmailErrorMessage] = useState();
    const [scoreDitailsDialog, setScoreDitailsDialog] = useState(false);
    const [message, setMessage] = useState();
    const [isViewAll, setIsViewAll] = useState(true);
    const [insightsItem, setInsightsItem] = useState([])
    const { state } = useLocation();
    const dispatch = useDispatch();
    const ref = useRef(null);
    const [loader, setLoader] = useState(false);
    const [getRefreshTokenStatus, setRefreshTokenData] = useState();
    const [getCreditReportStatus, setCreditReportStatus] = useState();
    const [isCollapsedCreditInfo, setIsCreditInfoCollapse] = useState(true);
    const [isCollapsedPersonalInfo, setIsPersonalCollapse] = useState(false);
    const [collapsedBankItems, setCollapsedBankItems] = useState(Array(0).fill(true));
    const [showModalPayload, setShowModalPayload] = useState({
        showModel: false,
        currentContent: 'EXPERIAN',
        msgErrorSuccess: ''
    });

    const handleOpenModal = () => setShowModalPayload({
        showModel: true,
        currentContent: 'EXPERIAN', msgErrorSuccess: ''
    });
    const handleCloseModal = () => setShowModalPayload({
        showModel: false,
        currentContent: 'EXPERIAN', msgErrorSuccess: ''
    });

    const [selectedPaymentModes, setSelectedPaymentModes] = useState({});
    const [selectedYearsData, setSelectedYearsData] = useState({});
    const [selectedDpdContent, setSelectedDpdContent] = useState({});
    const [selectedYearDPD, setSelectedYearDPD] = useState({});
    const refs = useRef({});


    const toggleBankCollapse = (index) => {
        const newCollapsedItems = [...collapsedBankItems];
        newCollapsedItems[index] = !newCollapsedItems[index];
        setCollapsedBankItems(newCollapsedItems);
    };
    const toggleCollapseCreditInfo = () => {
        setIsCreditInfoCollapse(!isCollapsedCreditInfo);
    };
    const toggleCollapsedPersonalInfo = () => {
        setIsPersonalCollapse(!isCollapsedPersonalInfo);
    };
    useEffect(() => {
        setMessage("")
        if (!user?.access_token) {
            navigate('/');
        } else {
            experianFetchConsent();
            getCreditScoreApi();
        }
    }, []);

    useEffect(() => {
        let defaultContent = {};


        if (creditReportData != null && creditReportData != undefined) {
            creditReportData.creditAccountInformationDetails.forEach((dpd, index) => {
                defaultContent[index] = (
                    <div className="d-flex mt-4 dpd-info-box good-btn-box">
                        <div className='thump-icon'>
                            <img src={thumpIcon} className='img-fluid' alt='defaulticon' />
                        </div>
                        <div className="ms-2 ms-sm-0">
                            <p className="mb-0 tinfi-title-text good-btn-box">Default payment information for {selectedYearDPD[index]} </p>
                            <p className="mb-0 title-message-text">No specific data available.</p>
                        </div>
                    </div>
                );
            });

        }
        setSelectedDpdContent(defaultContent);

    }, [creditReportData, selectedYearDPD, selectedYearsData]);

    const getCreditScoreApi = () => {
        const data = getUserData()
        if (bureau) {
            setSelectedBureau(bureau);
            getCreditReport(bureau);
            //getRefreshTokenData();
        } else {
            getCreditReport();
            //getRefreshTokenData();
        }
        setUserData(data)
        if (state?.success) {
            setMessage({ success: state?.success.statusMessage });
        } else if (state?.error) {
            setMessage({ error: state?.error.statusMessage });
        }
    }

    const openInsights = (name) => {
        setSelectedAnalysisName(name);
        setInsightsDialog(true)
        setDialogHeight('auto')
    }

    const openScoreDetails = (name) => {
        setScoreDitailsDialog(true)
        setDialogHeight('auto')
    }

    const openDialog = () => {
        setIsOpenDialogS(true)
    }

    const openDialogs = () => {
        setIsOpenDialogS(true)
    }

    const closeinsght = () => {
        setInsightsDialog(false)
        setDialogHeight('auto')
    }

    const closeScoreDetails = () => {
        setScoreDitailsDialog(false)
        setDialogHeight('auto')
    }

    const closeDialog = () => {
        setIsOpenDialog(false)
        setDialogHeight('auto')
    }

    const closeDialogS = () => {
        setIsOpenDialogS(false)
        setDialogHeight('auto')
    }

    const creditReportPull = () => {
        setLoader(true);
        const uuid = uuidv4();
        enhanceFetchCredit(uuid).then(resp => {

            setShowModalPayload(
                {
                    showModel: true,
                    currentContent: 'SUCCESS',
                    msgErrorSuccess: resp?.data.statusMessage
                }
            );
            setLoader(false);
            /// we need to hide bottom setMessage code 
            // if (resp?.data && resp?.data.statusCode === "SUCCESS") {
            //     setMessage({ success: resp?.data.statusMessage });
            // }
        }).catch(error => {

            setShowModalPayload({
                showModel: true, currentContent: 'ERROR',
                msgErrorSuccess: error?.response.data.errorMessage
            });
            setLoader(false)
            /// we need to hide bottom setMessage code 
            // setMessage({ error: error?.response.data.errorMessage });
        });
    }

    // const refreshTokenBtn = () => {
    //     setLoader(true);
    //     var param = {
    //       "isCreditReport": true
    //     }
    //     setMessage("")
    //     emailParseData(param).then(resp => {
    //       setLoader(false);
    //       if (resp.statusCode && resp.statusCode === "SUCCESS") {
    //         setMessage({ success: resp.statusMessage });
    //         navigate('/dashboard', { state: { "success": resp.statusMessage } });
    //         getCreditReport();
    //       } else {
    //         setMessage({ error: resp.statusMessage });
    //         navigate('/dashboard', { state: { "error": resp.statusMessage } });
    //       }
    //     }).catch(error => {
    //       setLoader(false);
    //       if (error.statusCode === "ERROR") {
    //         setMessage({ error: error.statusMessage });
    //         navigate('/dashboard', { state: { "error": error.statusMessage } });
    //       }
    //     })
    //   }

    // const submit = async () => {
    //     setMessage("")
    //     var param = {
    //         "email": email,
    //         "isCreditReport": true,
    //         "isBankStatement": false,
    //         "isBankStatementAsync": false
    //     }
    //     setLoader(true);
    //     emailVerification(param).then(resp => {
    //         setLoader(false);
    //         if (resp.statusCode === "SUCCESS") {
    //             window.location = resp.url;
    //         }
    //     }).catch(error => {
    //         setLoader(false);
    //         if (error.statusCode === "ERROR") {
    //             setMessage({ error: error.statusMessage });
    //             navigate('/dashboard', { state: { "error": error.statusMessage } });
    //         }
    //     })
    // }

    const { user, bureau } = useSelector(state => {
        return state.authReducer;
    });



    const getCreditReport = (bureau = '') => {
        setLoader(true)

        creditReport(bureau).then((resp) => {
            setCreditReportData(resp.data);
            if (resp.data && resp.data !== null) {
                setCollapsedBankItems(Array(2).fill(true))
                setSelectedPaymentModes({});
                setSelectedYearsData({})
                if (resp.data.creditAccountInformationDetails) {
                    let years = {}
                    resp.data.creditAccountInformationDetails.forEach((dpd, index) => {
                        years[index] = Object.keys(dpd.dpdPaymentHistory)[0]
                    });
                    setSelectedYearDPD(years)
                }
            }
            insightsAnalysisReports(resp.analysis)

            bureauDataGenerate(resp?.bureauName ?? '', resp?.availableOtherBureauData ?? []);
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
        })
    }

    const changePaymentMode = (index, optionVal) => {
        setSelectedPaymentModes(prevState => ({
            ...prevState,
            [index]: optionVal
        }));

        let tmpData = [];
        creditReportData.creditAccountInformationDetails.forEach((dpd, i) => {
            tmpData.push(dpd.dpdPaymentHistory);

        });
        updateDpdInfoContent(index, selectedYearDPD[index], tmpData, optionVal)

    }

    const changeYear = (selectedDPD, indexdt, selectedYear) => {

        setSelectedYearsData(prevState => ({
            ...prevState,
            [indexdt]: selectedDPD
        }));

        setSelectedYearDPD(prevState => ({
            ...prevState,
            [indexdt]: selectedYear
        }));
        updateDpdInfoContent(indexdt, selectedYear, selectedDPD, selectedPaymentModes[indexdt])

    };


    const handleSetSelectedDpdContent = (index, dpdVal, month, dpdDatas) => {
        let content;

        if (dpdVal === "0" || dpdVal === "S") {
            content = (
                <div className="d-flex mt-4 dpd-info-box good-btn-box">
                    <div className='thump-icon'>
                        <img src={thumpIcon} className='img-fluid' alt='thumpicon' />
                    </div>
                    <div className="ms-2 ms-sm-0">
                        <p className="mb-0 tinfi-title-text good-btn-box">On time payment in the month of {month === '' ? selectedYearDPD[index] : month}.</p>
                        <p className="mb-0 title-message-text">Great! Keep paying on time.</p>
                    </div>
                </div>
            );
        } else if (dpdVal >= 1 && dpdVal <= 89) {
            content = (
                <div className="d-flex mt-4 dpd-info-box delaydpd-btn-box">
                    <div className='thump-icon'>
                        <img src={delayIcon} className='img-fluid' alt='delayicon' />
                    </div>
                    <div className="ms-2 ms-sm-0">
                        <p className="mb-0 tinfi-title-text delaydpd-btn-box">Delayed payments in the above {dpdDatas.length == 0 ? selectedYearDPD[index] : 'months'}.</p>
                        <p className="mb-0 title-message-text">Delayed payments decrease your Credit Score.</p>
                    </div>
                </div>
            );
        } else if (dpdVal >= 90) {
            content = (
                <div className="d-flex mt-4 dpd-info-box overdue-btn-box">
                    <div className='thump-icon'>
                        <img src={overdueIcon} className='img-fluid' alt='overdueicon' />
                    </div>
                    <div className="ms-2 ms-sm-0">
                        <p className="mb-0 tinfi-title-text overdue-btn-box">Overdue payments in the above {dpdDatas.length == 0 ? selectedYearDPD[index] : 'months'}</p>
                        <p className="mb-0 title-message-text">Delayed payments decrease your Credit Score.</p>
                    </div>
                </div>
            );
        } else if (dpdVal == '') {
            content = (
                <div className="d-flex mt-4 dpd-info-box nodpd-btn-box">
                    <div className='thump-icon'>
                        <img src={noDpdIcon} className='img-fluid' alt='nodpdicon' />
                    </div>
                    <div className="ms-2 ms-sm-0">
                        <p className="mb-0 tinfi-title-text nodpd-btn-box">Payment history not available for N/A.</p>
                        <p className="mb-0 title-message-text">No information from bureau.</p>
                    </div>
                </div>
            );
        } else {
            content = (
                <div className="d-flex mt-4 dpd-info-box nodpd-btn-box">
                    <div className='thump-icon'>
                        <img src={noDpdIcon} className='img-fluid' alt='nodpdicon' />
                    </div>
                    <div className="ms-2 ms-sm-0">
                        <p className="mb-0 tinfi-title-text nodpd-btn-box">Payment history not available for {dpdDatas.length == 0 ? selectedYearDPD[index] : 'months'}.</p>
                        <p className="mb-0 title-message-text">No information from bureau.</p>
                    </div>
                </div>
            );
        }

        setSelectedDpdContent(prevState => ({
            ...prevState,
            [index]: content
        }));
    };

    const filterDpdData = (selectedYears, dpdPayment, selectedPaymentMode) => {
        let filterData = [];


        if (selectedYears && selectedYears.length > 0) {
            filterData = Object.values(selectedYears);
        } else {
            filterData = dpdPayment; // Assuming dpdPayment is already an array
        }

        switch (selectedPaymentMode) {
            case "on_time":
                filterData = filterData.filter(dpdValues => dpdValues.value === '0' || dpdValues.value === 'S');
                break;
            case "deplayed":
                filterData = filterData.filter(dpdValues => dpdValues.value >= 1 && dpdValues.value <= 89);
                break;
            case "overdue":
                filterData = filterData.filter(dpdValues => dpdValues.value >= 90);
                break;
            default:
                filterData = filterData;
                break;
        }
        return filterData;
    };

    const updateDpdInfoContent = (index, year, dpdData, paymentMode) => {
        const filteredData = filterDpdData(year, dpdData, paymentMode);

        if (filteredData.length === 0) {
            let dpdVal, month = '';
            switch (paymentMode) {
                case "on_time":
                    dpdVal = '0';
                    break;
                case "deplayed":
                    dpdVal = '16';
                    break;
                case "overdue":
                    dpdVal = '95';
                    break;
                default:
                    dpdVal = '0';
                    break;
            }
            handleSetSelectedDpdContent(index, dpdVal, month, filteredData);
        } else {

            handleSetSelectedDpdContent(index, '0', year, dpdData);
        }
    };

    const DpdBox = ({ id, dpdPayment, selectedPaymentMode, selectedYearsData, setSelectedDpdContent }) => {
        let filterData = [];

        if (selectedYearsData && selectedYearsData.length > 0) {
            filterData = Object.values(selectedYearsData);
        } else {
            filterData = Object.entries(dpdPayment)[0][1];
        }

        if (selectedPaymentMode === "on_time") {
            filterData = filterData.filter(dpdValues => dpdValues.value === '0' || dpdValues.value === 'S');
        } else if (selectedPaymentMode === "deplayed") {
            filterData = filterData.filter(dpdValues => dpdValues.value >= 1 && dpdValues.value <= 89);
        } else if (selectedPaymentMode === "overdue") {
            filterData = filterData.filter(dpdValues => dpdValues.value >= 90);
        }

        return (
            <>
                {filterData.map(dpd => {
                    let dpdVal;
                    let clsName;
                    if (dpd.value === "0" || dpd.value === "S") {
                        dpdVal = <img src={paymentDoneIcon} className='img-fluid' alt='paymentDoneicon' />;
                        clsName = 'box-area';
                    } else if (dpd.value >= 1 && dpd.value <= 89) {
                        dpdVal = <span className='no-of-days-late'>{dpd.value}</span>;
                        clsName = 'box-area days-late';
                    } else if (dpd.value > 90) {
                        dpdVal = <span className='pls-days-late'>{dpd.value}</span>;
                        clsName = 'box-area plus-days-late';
                    } else {
                        dpdVal = dpd.value;
                        clsName = 'box-area not-defined-day';
                    }

                    return (
                        <div className='day-box text-center my-1 ps-2' key={dpd.month}>
                            <h1 className='box-month-title'>{dpd.month}</h1>
                            <div className={clsName} onClick={() => setSelectedDpdContent(id, dpd.value, dpd.month, filterData)}>
                                <div className='dpd-val p-0 m-0'>{dpdVal == '' ? '-' : dpdVal}</div>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    };

    const experianFetchConsent = () => {
        fetchFetchExperian().then((resp) => {
            if (resp.data != null) {
                if (resp.data.statusCode === "SUCCESS" &&
                    resp.data.experianConsentStatus != 'ACTIVE') {
                    ///open dialog for experian fetch consent
                    handleOpenModal();
                }
            }
        }).catch((error) => {
            //  console.log(error);
        })
    }
    const updateExperianConsentApi = () => {
        updateExperianConsent().then((resp) => {
            if (resp.data != null) {
                if (resp.data.statusCode === "SUCCESS") {
                    ///credit pull after success of consent 
                    creditReportPull()
                }
            }
        }).catch((error) => {
            setShowModalPayload(
                {
                    showModel: true,
                    currentContent: 'ERROR',
                    msgErrorSuccess: error?.response.data.errorMessage
                }
            );
        })
    }

    const insightsAnalysisReports = (analysis) => {
        setAnalysisReportData(analysis)
        var i = [];
        if (analysis && analysis.paymentHistory) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 pt-3 pb-2">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={PaymentsIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.label && analysis?.paymentHistory?.label != null) ? analysis.paymentHistory.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.impactOnScore && analysis?.paymentHistory?.impactOnScore !== null) ? analysis.paymentHistory.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('paymentHistory') }}>
                                    <p className="card-text insights-card-main-text  p-0 m-0">{(analysis && analysis !== null) ? (analysis?.paymentHistory?.information?.title && analysis?.paymentHistory?.information?.title !== null) ? analysis.paymentHistory.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.paymentHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'} >{(analysis && analysis !== null) ? (analysis?.paymentHistory?.ratio && analysis?.paymentHistory?.ratio !== null) ? (analysis.paymentHistory.ratio + '%') : 'p-0 m-0' : 'p-0 m-0'}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.accounts) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 pt-3 pb-2">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={AccountIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.accounts?.label && analysis?.accounts?.label !== null) ? analysis.accounts.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.accounts?.impactOnScore && analysis?.accounts?.impactOnScore !== null) ? analysis.accounts.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('accounts') }}>
                                    <p className="card-text insights-card-main-text p-0 m-0">{(analysis && analysis !== null) ? (analysis?.accounts?.information?.title && analysis?.accounts?.information?.title !== null) ? analysis.accounts.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        {analysis?.accounts?.noOfActiveAccounts && <h5 className={(analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.accounts?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.accounts?.noOfActiveAccounts && analysis?.accounts?.noOfActiveAccounts !== null) ? analysis.accounts.noOfActiveAccounts : '' : ''} Active</h5>}
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.creditHistory) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 pt-3 pb-2">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={AgeIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.creditHistory?.label && analysis?.creditHistory?.label !== null) ? analysis.creditHistory.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.creditHistory?.impactOnScore && analysis?.creditHistory?.impactOnScore !== null) ? analysis.creditHistory.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditHistory') }}>
                                    <p className="card-text insights-card-main-text p-0 m-0">{(analysis && analysis !== null) ? (analysis?.creditHistory?.information?.title && analysis?.creditHistory?.information?.title !== null) ? analysis.creditHistory.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.creditHistory?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.creditHistory?.ageOfAccounts && analysis?.creditHistory?.ageOfAccounts !== null) ? analysis.creditHistory.ageOfAccounts : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.creditUtilization) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 pt-3 pb-2">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={LimitIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.label && analysis?.creditUtilization?.label !== null) ? analysis.creditUtilization.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.impactOnScore && analysis?.creditUtilization?.impactOnScore !== null) ? analysis.creditUtilization.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('creditUtilization') }}>
                                    <p className="card-text insights-card-main-text p-0 m-0">{(analysis && analysis !== null) ? (analysis?.creditUtilization?.information?.title && analysis?.creditUtilization?.information?.title !== null) ? analysis.creditUtilization.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.creditUtilization?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.creditUtilization?.ratio && analysis?.creditUtilization?.ratio !== null) ? (analysis.creditUtilization.ratio + '%') : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        if (analysis && analysis.enquiries) {
            i.push(
                <div className='mx-2 mx-md-3'>
                    <div className="col insights-main-box-height">
                        <div className='insights-sub-main-box h-100'>
                            <div className="cards h-100">
                                <div className="card-bod px-2 pt-3 pb-2">
                                    <div className='insights-imag-box mb-2'>
                                        <img src={EnquiriesIcon} className='img-fluid' />
                                    </div>
                                    <h5 className="card-title insights-box-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.enquiries?.label && analysis?.enquiries?.label !== null) ? analysis.enquiries.label : '' : ''}</h5>
                                    <p className="card-text insights-card-main-title p-0 m-0">{(analysis && analysis !== null) ? (analysis?.enquiries?.impactOnScore && analysis?.enquiries?.impactOnScore !== null) ? analysis.enquiries.impactOnScore : '' : ''}</p>
                                </div>
                                <div className={(analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'px-2 py-3 limit-box dialog-boxes' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'px-2 py-3 age-box dialog-boxes' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'px-2 py-3 payment-box dialog-boxes' : 'px-2 py-3'} onClick={() => { openInsights('enquiries') }}>
                                    <p className="card-text insights-card-main-text">{(analysis && analysis !== null) ? (analysis?.enquiries?.information?.title && analysis?.enquiries?.information?.title !== null) ? analysis.enquiries.information?.title : '' : ''}</p>
                                    <div className='d-flex justify-content-between align-items-center mt-1'>
                                        <h5 className={(analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'good' || (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'excellent' ? 'card-title accounts-box-title-text' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'bad' ? 'card-title age-box-text-icon' : (analysis?.enquiries?.impactStatus ?? '').toLowerCase() === 'fair' ? 'card-title paymet-text-title' : 'card-title'}>{(analysis && analysis !== null) ? (analysis?.enquiries?.totalNoOfEnquiry && analysis?.enquiries?.totalNoOfEnquiry !== null) ? analysis.enquiries.totalNoOfEnquiry : '' : ''}</h5>
                                        <i className="bi bi-chevron-right right-ariw-icons"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        setInsightsItem(i)
    }

    const responsives = {
        // 0: {
        //     items: 2
        // },
        // 767: {
        //     items: 2
        // },
        // 1024: {
        //     items: 5,
        //     itemsFit: 'contain',
        //     disableButtonsControls: 'true',
        //     infinite: 'true'
        // },
        0: {
            items: 1.8
        },
        360: {
            items: 2
        },
        375: {
            items: 2
        },
        390: {
            items: 2.1
        },
        412: {
            items: 2.2
        },
        414: {
            items: 2.3
        },
        430: {
            items: 2.3
        },
        540: {
            items: 3,
        },
        576: {
            items: 3
        },
        768: {
            items: 4,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
        820: {
            items: 4.2
        },
        1024: {
            items: 4,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
        1200: {
            items: 5,
            itemsFit: 'contain',
            disableButtonsControls: 'true',
            infinite: 'true'
        },
    };

    const bureauDataGenerate = (bureauName, bureaudata) => {
        setSelectedBureau(bureauName);
        var bureauListData = bureauList;
        if (!bureauListData.includes(bureauName)) {
            bureauListData.push(bureauName)
        }
        bureaudata.forEach(element => {
            if (!bureauListData.includes(element)) {
                bureauListData.push(element)
            }
        });
        setBureauList(bureauListData);
    }


    const accountDetails = (accountess, personalInformation) => {
        var accounts = [];
        for (let index = 0; index < accountess.length; index++) {
            accounts.push(
                <div className="accordion-item my-3" key={"accordionItem" + index}>
                    <div className="accordion-header accordion-button align-items-center collapsed" type="button"
                        onClick={() => toggleBankCollapse(index)}
                        // data-bs-toggle="collapse"
                        data-bs-target={"#collapseOne" + index}
                        aria-expanded={!collapsedBankItems[index]}
                        aria-controls={"collapseOne" + index}>
                        <div className='row w-100 align-items-center g-2 g-sm-0'>
                            <div className='col-12 p-0 m-0'>
                                <div className='d-flex  justify-content-between align-items-center'>
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <div className='bank-image-box'>
                                            <img src={AxisBank} width={100}></img>
                                        </div>
                                        <div className='text-start ms-3 p-0 m-0 pt-2'>
                                            <div className='row d-flex align-items-center p-0 m-0'>
                                                <div className='col-12 p-0 m-0'>
                                                    <h1 className='bank-name-text p-0 m-0'>{accountess[index].creditAccountInformation.lender ?? "N/A"}</h1>
                                                </div>
                                            </div>
                                            <div className='row d-flex align-items-center'>
                                                <div className='col-12 p-0 m-0'>
                                                    <p className='acc-type-text pt-2 m-0'>{accountess[index].creditAccountInformation.accountType + " | " +
                                                        `${accountess[index].creditAccountTerms.accountNumber ?? 'N/A'}` ?? "N/A"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='pe-2'>
                                        <div className='d-flex flex-row justify-content-center align-items-center'>
                                            {(accountess[index].creditAccountDescription.accountStatus && accountess[index].creditAccountDescription.accountStatus != null && accountess[index].creditAccountDescription.accountStatus !== '') &&
                                                (<div className='pe-2'>
                                                    <div className={accountess[index].creditAccountDescription.accountStatus === 'CLOSED'
                                                        || accountess[index].creditAccountDescription.accountStatus == 'CLOSED' ? 'box-active-bank box-close-bank m-0' :
                                                        'box-active-bank m-0'}>
                                                        {accountess[index].creditAccountDescription.accountStatus ?? ''}</div>
                                                </div>)}
                                            <img src={ICArrowUp} className={`arrow_icon ${collapsedBankItems[index] ? 'rotate-180' : ''}`}></img>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* <div className='col-12 col-sm-5'>
                                <span className='mb-1 progress-text'>50% Payment done</span>
                                <div className='progresssbars'>
                                    <ProgressBar completed={50} labelClassName="labels" barContainerClassName="barcompleted" />
                                </div>
                            </div> */}
                            {/* <div className='col-12 col-sm-3'>
                                <div className='tetx-end me-sm-3 d-flex justify-content-between d-sm-block'>
                                    {accountess[index].creditAccountDetails.creditLimitAmount !== '' && accountess[index].creditAccountDetails.creditLimitAmount !== '-' && <h1 className='text-start text-sm-end dolars-rs-text mb-0'>₹  {accountess[index].creditAccountDetails.creditLimitAmount ?? ""}</h1>}
                                    <div className='credit-report-amount-box float-sm-end float-start'>
                                        <div className='good-stand-box'>Good Standing</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div id={"collapseOne" + index}
                        className={"accordion-collapse collapse" + (collapsedBankItems[index] ? "" : " show")}
                        aria-labelledby={"headingOne" + index}>
                        <div className="accordion-body">
                            <div className='row g-2'>
                                <div className='col-lg-4'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text  m-0 py-3'>Account Term</p>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Account number</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.accountNumber ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Opened</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.dateOpened ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Closed</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.dateClosed ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Ownership</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.ownership ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Rate Of Interest</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.rateOfInterest ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Value Of Collateral</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.valueOfCollateral ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Type Of Collateral</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountTerms.typeOfCollateral ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 ps-lg-2 pt-sm-0 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text m-0 py-3'>Account Description</p>
                                        <div className='dividers'></div>
                                        <div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date Reported</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.dateReported ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Loan Type</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.loanType ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Account Status</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.accountStatus ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Highest Credit</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.highestCredit ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Current balance</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.currentBalance ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Amount overdue</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.amountOverdue ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Last Payment Date</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDescription.lastPaymentDate ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 ps-lg-2 pt-sm-0 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text m-0 py-3'>Account Details</p>
                                        <div className='dividers'></div>
                                        <div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Credit Limit Amt</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.creditLimitAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Emi</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.emiAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Repayment Tenure</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.repaymentTenure ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Total Write Off</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.totalWriteOffAmount ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Principal Write Off</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.principalWriteOff ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Settlement Amt</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.settlementAmount ?? "N/A"}</p>
                                                </div>
                                            </div>

                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-5 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Write Off Settle</p>
                                                </div>
                                                <div className='col-7 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{accountess[index].creditAccountDetails.writtenOffSettleStatus ?? "N/A"}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='row g-2 mt-3'>
                                <div className='col-lg-6 pe-lg-2'>
                                    <div className='account-details-box p-0 m-0'>
                                        <p className='account-details-text  m-0 py-3'>Personal Information</p>
                                        <div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Name</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.name ?? "-"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Date of Birth</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.dateOfBirth ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Gender</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.gender ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Mobile Number</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.mobileNumber ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Email Address</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation.eamilAddress ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>Occupation</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.employmentInfo?.occupation ?? "N/A"}</p>
                                                </div>
                                            </div>
                                            <div className='dividers'></div>
                                            <div className='row p-0 m-0'>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-main-text account-details-main-text-new text-start'>PAN</p>
                                                </div>
                                                <div className='col-6 p-0 m-0'>
                                                    <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{personalInformation?.pan ?? "N/A"}</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 pt-sm-0 ps-lg-2 pt-md-2 pt-lg-0 pt-md-0 pt-sm-2 pt-2'>
                                    <div className='account-details-box p-0 m-0 '>
                                        <p className='account-details-text m-0 py-3'>Address</p>
                                        <div className='dividers'></div>
                                        <div className={`address-box${!accountess[index].creditAccountInformation?.addresses ||
                                            accountess[index].creditAccountInformation?.addresses.length === 0 ? ' no-scroll-address-box' : ''}`}>
                                            {(accountess[index].creditAccountInformation?.addresses && accountess[index].creditAccountInformation?.addresses.length > 0) ?
                                                accountess[index].creditAccountInformation?.addresses.map((address, idx) => (
                                                    <div key={idx}>
                                                        {idx !== 0 && <div className='dividers'></div>}
                                                        <div className='row p-0 m-0'>
                                                            <div className='col-5 p-0 m-0'>
                                                                <p className='account-details-main-text account-details-main-text-new text-start'>Address {idx + 1}</p>
                                                            </div>
                                                            <div className='col-7 p-0 m-0'>
                                                                <p className='account-details-sub-text account-details-sub-text-new text-end'>{address ?? "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )) :
                                                <div className='d-flex h-100 align-items-center justify-content-center '>
                                                    <p className='p-0 m-0 px-1 text-center no-address-line'>There's no address associated with this account.</p>
                                                </div>
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>

                            {Object.keys(accountess[index].dpdPaymentHistory).length > 0 && (
                                <div className='row g-2 mt-3'>
                                    <div className='col-lg-12 pe-lg-2'>
                                        <div className='account-details-box p-0 m-0'>
                                            <p className='account-details-text m-0 py-3'>Personal History</p>
                                            <div>
                                                <div className='dividers'></div>
                                                <div className='row p-0 m-0 '>
                                                    <div className='col-12 d-flex flex-wrap pb-3 pt-2 justify-content-between'>
                                                        <div className="payment-title py-2">Days Past Due</div>
                                                        <div className='flex-row justify-content-end p-0 m-0 parent-dpd-options'>
                                                            <div className='dropdown-section p-0 m-0'>
                                                                <div className='pe-1 '>
                                                                    <select className="form-select" onChange={(e) => changePaymentMode(index, e.target.value)}>
                                                                        <option value="">Select Type</option>
                                                                        <option value="on_time">On time payment</option>
                                                                        <option value="deplayed">Delayed payments</option>
                                                                        <option value="overdue">Overdue payments</option>
                                                                        <option value="clear">Clear filters</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            {Object.keys(accountess[index].dpdPaymentHistory).length > 0 && (
                                                                <div className='dropdown-section p-0 m-0'>
                                                                    <select className="form-select" name={'dpdAccount' + index} onChange={(e) => changeYear(accountess[index].dpdPaymentHistory[e.target.value], index, e.target.value)}>
                                                                        {Object.entries(accountess[index].dpdPaymentHistory).map(([fieldkey, fieldvalue]) => (
                                                                            <option key={fieldkey} value={fieldkey}>{fieldkey}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row p-0 m-0'>
                                                    <div className=' d-flex flex-wrap p-0 m-0 ps-2' id={'dpdBox' + index} ref={(el) => (refs.current[index] = el)}>
                                                        <DpdBox
                                                            id={index}
                                                            dpdPayment={accountess[index].dpdPaymentHistory}
                                                            selectedPaymentMode={selectedPaymentModes[index]}
                                                            selectedYearsData={selectedYearsData[index]}
                                                            setSelectedDpdContent={handleSetSelectedDpdContent}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row pb-3">
                                                    <div className='col-lg-12 col-md-12 col-sm-12 d-flex flex-wrap'>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 pe-lg-2 pe-md-2 pe-sm-2">
                                                            {selectedDpdContent[index]}
                                                        </div>
                                                        <div className="col-lg-6 col-md-6 col-sm-6 d-flex mt-3 dpd-range-box">
                                                            <div className='d-flex flex-wrap'>
                                                                <div><img src={paymentDoneIcon} className='img-fluid' alt='paymenticon' /><span className='ps-1' >On time payment</span></div>
                                                                <div className='ps-2'><img src={days89Icon} className='img-fluid' alt='day89icon' /><span className='ps-2'>01-89 Days Delay</span></div>
                                                                <div className='ps-2'><img src={days90Icon} className='img-fluid' alt='day90icon' /><span className='ps-2'>90+ Days late </span></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}


                        </div>
                    </div>
                </div >
            )
        }
        return accounts;
    }


    const personalInformationAddresViewAll = () => {
        setIsViewAll(false);
        document.getElementById("personalInformation").style.height = "100%";
    }

    const onPersonalInformationExpand = () => {
        if (ref.current != null) {
            document.getElementById("personalInformation").style.height = (ref.current.clientHeight - 19).toString() + "px";
        }
    }

    const Control = ({ children, ...props }) => {
        const [inputValue, setInputValue] = useState('');
        const { selectProps, hasValue } = props;

        return (
            <components.Control {...props}>
                <div style={{ position: 'relative', display: 'flex', }}>
                    {hasValue || inputValue ? (
                        <label
                            style={{
                                position: 'absolute',
                                left: '6px',
                                top: '-24px',
                                backgroundColor: 'white',
                                padding: '0 4px',
                                fontSize: '80%',
                                fontWeight: '400',
                                fontFamily: 'Poppins',
                                color: '#000000',
                            }}
                        >
                            {selectProps.placeholder}
                        </label>
                    ) : null}
                    {children}
                </div>
            </components.Control>
        );
    };


    return (
        <>
            {loader && <Loader />}
            <div className='mt-4 mt-sm-2'>
                <section className='' id='creditscore'>

                    <div className='container-lg container-fluid mt-3 ps-lg-3'>
                        <h1 className='header-title mb-2 mb-sm-3'>Credit Report</h1>
                    </div>
                    {bureauList.length !== 0 && <div className='container-lg container-fluid my-sm-3 pt-2 ps-lg-3'>
                        <Select
                            styles={customStyles}
                            components={{ Control }}
                            defaultValue={{ value: bureauList[0], label: bureauList[0] }}
                            placeholder='Select Credit Bureau'
                            isSearchable={false}
                            options={bureauList.map((bureau) => ({
                                value: bureau,
                                label: bureau,
                            }))}
                            onChange={(bureau) => {
                                setSelectedBureau(bureau.value)
                                dispatch(dispatchAction(SELECTED_BUREAU, bureau.value, false));
                                getCreditReport(bureau.value);
                                setIsViewAll(true)
                            }}
                            formatOptionLabel={bureau => (
                                <div className="country-option">
                                    <img src={
                                        bureau.value == "EXPERIAN" ? ExperianLogo :
                                            bureau.value == "CIBIL" ? CibillLogo :
                                                bureau.value == "EQUIFAX" ? EquifaxLogo : CrifLogo} alt="dropdown-logo" className='dropdown-logo' />
                                </div>
                            )}
                        />
                    </div>}
                    <div className='container-lg container-fluid  mt-3 my-sm-3'>
                        <div className='row align-items-start align-items-lg-center align-items-md-center g-1 g-sm-2 my-2 mb-3 px-sm-2 px-2 px-md-0'>
                            <div className='col-5'>
                                <div className='ps-sm-2'>
                                    <h1 className='username-text mb-2 mb-sm-3'>Hi {userData?.given_name}!</h1>
                                    <p className='credit-bureau-text mb-2'>Check Out Your <span className='bold-txt'>{selectedBureau} Credit Report</span></p>
                                    <p className='credit-bureau-text mb-1'>Next Report In :  <span className='bold-txt'> 27 Days</span></p>
                                    <div className='d-flex flex-column flex-lg-row flex-md-row flex-sm-row align-content-center
                                      align-items-start align-items-lg-center align-items-md-center align-items-sm-center'>
                                        <p className='credit-bureau-text pe-2 m-0'>Get latest credit score: </p>
                                        <div className="refresh-score-btn" onClick={() => {
                                            handleOpenModal();
                                        }}>
                                            <div className='d-flex flex-row align-content-center align-items-center p-0 m-0'>
                                                <span className="bi bi-arrow-repeat p-0 m-0 icon-refresh" ></span>
                                                <p className='ps-2 refresh-score-text'>Refresh Score</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-7'>
                                <div className='d-sm-flex justify-content-between justify-content-md-end align-items-center'>
                                    <div className='text-end text-sm-end me-sm-2'>
                                        <p className='mb-0 userscore-textes'>Your Credit score</p>
                                        <h1 className={creditReportData && creditReportData !== null && creditReportData?.score?.score > 0 ? 'credit-score' : 'credit-score0'} >
                                            {(creditReportData && creditReportData !== null) ? (creditReportData?.score?.score && creditReportData?.score?.score !== null && Number(creditReportData?.score?.score) >= 0) ? creditReportData.score.score : '0' : '---'}
                                        </h1>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-end justify-content-md-center'>
                                        <div className='order-1 order-sm-0 mx-0 mx-sm-2'>
                                            {(creditReportData?.score?.score >= 780 && creditReportData?.score?.score <= 900) &&
                                                <div className='excellect-box score-box'>
                                                    <p className='mb-0 score-box-text'>Excellent</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 706 && creditReportData?.score?.score <= 779) &&
                                                <div className='goods-box score-box'>
                                                    <p className='mb-0 score-box-text'>Good</p>
                                                </div>}
                                            {(creditReportData?.score?.score >= 631 && creditReportData?.score?.score <= 705) &&
                                                <div className='average-box score-box'>
                                                    <p className='mb-0 score-box-text'>Average</p>
                                                </div>}
                                            {(creditReportData?.score?.score <= 630) &&
                                                <div className='poor-box score-box'>
                                                    <p className='mb-0 score-box-text'>Poor</p>
                                                </div>}
                                            {(creditReportData?.score?.score === undefined || creditReportData?.score?.score === null || creditReportData?.score?.score === 0) &&
                                                <div className='score-not-show-box score-box mx-2'>
                                                    <p className='mb-0 score-box-text'><i className="bi bi-emoji-frown score-not-show-text"></i></p>
                                                </div>}
                                        </div>
                                        <div className='order-0 order-sm-1'><i className="bi bi-info-circle score-info-icon me-1" onClick={() => { openScoreDetails() }}></i></div>
                                    </div>
                                </div>
                            </div>
                            <div className='crdit-score-dailog'>
                                {scoreDitailsDialog && (
                                    <ReactDialogBox
                                        closeBox={closeScoreDetails}
                                        headerBackgroundColor='white'
                                        headerTextColor='black'
                                        closeButtonColor='black'
                                        bodyBackgroundColor='white'
                                        bodyTextColor='black'
                                        headerText="Credit Score Range"
                                        bodyHeight={dialogHeight}
                                        borderRadius='8px'
                                        headerHeight='65px'
                                    >
                                        <ScoreDetails closeScoreDetails={closeScoreDetails} setDialogHeight={setDialogHeight} />
                                    </ReactDialogBox>)}
                            </div>
                        </div>
                    </div>
                </section>

                <section>
                    <ErrorMessage message={message} state={state} />
                </section>

                {/* <section id="refresh-score">
                    <div className='container-lg container-fluid my-3'>
                        <div className="show-message-box alert-success d-flex align-items-center w-100 justify-content-between">
                            <div className='d-flex align-items-center align-items-sm-center'>
                                <div className="circle red"></div>
                                <p className='mb-0 ms-3 error-message-text-email'>Credit report pull to get the latest Credit Score, Insights and much more.</p>
                            </div>
                            <button type="button" className="message-box-btn " onClick={() => {

                                handleOpenModal();
                                //creditReportPull()
                            }}>
                                <span className="bi bi-arrow-repeat"></span>
                                <span className="msg-btn-text">Fetch Credit Report</span>
                            </button>
                        </div>
                    </div>
                    <div className='container-lg container-fluid my-3'>
                        <button type="button" className="message-box-btn score-btn-dis" onClick={() => {
                            handleOpenModal();
                            // creditReportPull()
                        }}>
                            <span className="bi bi-arrow-repeat"></span>
                            <span className="msg-btn-text">Fetch Credit Report</span>
                        </button>
                    </div>
                </section> */}

                {showModalPayload.showModel &&
                    <ExperianModal onClickExpFetch={() => {
                        updateExperianConsentApi()

                    }} onClose={handleCloseModal}
                        msgErrorSuccess={showModalPayload.msgErrorSuccess}
                        showTypeContent={showModalPayload.currentContent} />
                }


                {/* {getRefreshTokenStatus && getRefreshTokenStatus.statusCode && getRefreshTokenStatus.statusCode === "SUCCESS" &&
                  <section id="refresh-score">                        
                    <div className='container-lg container-fluid my-3'>
                        <div className="show-message-box alert-success d-flex align-items-center w-100 justify-content-between">
                            <div className='d-flex align-items-center align-items-sm-center'>
                                <div className="circle red"></div>
                                <p className='mb-0 ms-3 error-message-text-email'>Refresh Score to get latest Credit Score, Insights and much more.</p>
                            </div>
                            <button type="button" className="message-box-btn " onClick={() => { refreshTokenBtn() }}>
                                <span className="bi bi-arrow-repeat"></span>
                                <span className="msg-btn-text">Refresh Score</span>
                            </button>
                        </div>
                    </div>
                    <div className='container-lg container-fluid my-3'>
                        <button type="button" className="message-box-btn score-btn-dis" onClick={() => { refreshTokenBtn() }}>
                                    <span className="bi bi-arrow-repeat"></span>
                                    <span className="msg-btn-text">Refresh Score</span>
                        </button>
                     </div>
                </section> }     */}

                <section id='insights'>
                    <div className='container-lg container-fluid my-3'>
                        {analysisReportData && <div className='insights-main-box'>
                            <div className='row g-2'>
                                <div className='col-12 p-0'>
                                    <p className='insights-heding-text py-2 ps-2 ps-lg-4 ps-md-3 m-0'>INSIGHTS</p>
                                </div>
                            </div>
                            <div className='dividers w-100'></div>
                            <div className='row g-2'>
                                <div className='col-12 p-0 m-0'>
                                    <p className='ps-2 ps-lg-4 ps-md-3 m-0 pt-3 insights-sub-text'>Check out the factors affecting your credit score!</p>
                                </div>
                                {/*  px-xl-5 */}
                                <div className='col-12 parent-insight'>
                                    <AliceCarousel
                                        items={insightsItem}
                                        responsive={responsives}>
                                    </AliceCarousel>
                                    <div id='insightdialog'>
                                        {insightsDialog && (
                                            <ReactDialogBox
                                                closeBox={closeinsght}
                                                headerBackgroundColor='white'
                                                headerTextColor='black'
                                                closeButtonColor='black'
                                                bodyBackgroundColor='white'
                                                bodyTextColor='black'
                                                bodyHeight={dialogHeight}
                                                borderRadius='8px'
                                            >
                                                <Insights closeDialog={closeinsght} setDialogHeight={setDialogHeight} setAnalysisReportData={setAnalysisReportData} selectedAnalysisName={selectedAnalysisName} analysisReportData={analysisReportData[selectedAnalysisName]} />
                                            </ReactDialogBox>)}
                                    </div>
                                </div>
                            </div>
                        </div>}
                    </div>
                </section >

                {creditReportData?.creditAccountInformationDetails && <section id='summatyaccount'>
                    <div className='container-lg container-fluid my-3'>
                        <div className="accordion" id="accordionPanelsStayOpenExample">
                            <div className="accordion-item">
                                <h2 id='accountInformationButtons'
                                    className={`accordion-header accordion-button align-items-start pb-0 p-0 ${isCollapsedCreditInfo ? 'collapsed' : ''}`}
                                    type="button" data-bs-target="#panelsStayOpen-collapseOne"
                                    // className="accordion-header accordion-header accordion-button align-items-start pb-0 collapsed p-0" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne"
                                    aria-controls="panelsStayOpen-collapseOne" onClick={toggleCollapseCreditInfo}>
                                    <div className='w-100 m-0 pb-3'>
                                        <div className='d-flex justify-content-between py-3 align-items-center ps-2 ps-lg-4 ps-md-3'>
                                            <p className="acc-info-text m-0">Credit Account Information</p>
                                            <div className='pe-3'>
                                                <img src={ICArrowUp} className={`arrow_icon ${isCollapsedCreditInfo ? 'rotate-180' : ''}`}></img>
                                            </div>
                                            {/* <span className='text-end me-2 view-text-all'>View All</span> */}
                                        </div>
                                        {creditReportData && creditReportData?.reportSummary && <div className='dividers w-100'></div>}

                                        {creditReportData && creditReportData?.reportSummary && <section>
                                            <div className='my-2 ps-2 ps-lg-4 ps-md-3'>
                                                <div className='row align-items-center g-3'>
                                                    <div className='col-lg-2'>
                                                        <p className='account-summary-text'>Account Summary</p>
                                                    </div>
                                                    <div className='col-lg-10 row g-2'>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='balance-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Balance</p>
                                                                    {creditReportData?.reportSummary?.currentBalanceAccountSummary && <h3 className='summary-numbers'>₹  {(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount && creditReportData?.reportSummary?.currentBalanceAccountSummary?.totalCurrentBalanceAmount != null) ? creditReportData.reportSummary.currentBalanceAccountSummary.totalCurrentBalanceAmount : '-' : '-'}</h3>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='payments-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Payments</p>
                                                                    <h3 className='summary-numbers'> -</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='open-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Open Accounts</p>
                                                                    <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts && creditReportData?.reportSummary?.creditAccountSummary?.activeAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.activeAccounts : '-' : '-'}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-6 col-md-3'>
                                                            <div className='acsummary-box'>
                                                                <div className='closed-left-border-box'></div>
                                                                <div className='text-center mx-auto'>
                                                                    <p className='summary-sub-text'>Closed Accounts</p>
                                                                    <h3 className='summary-numbers'>{(creditReportData && creditReportData !== null) ? (creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts && creditReportData?.reportSummary?.creditAccountSummary?.closedAccounts != null) ? creditReportData.reportSummary.creditAccountSummary.closedAccounts : '-' : '-'}</h3>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>}
                                    </div>
                                </h2>
                                <div id="panelsStayOpen-collapseOne" className={`accordion-collapse collapse ${isCollapsedCreditInfo ? '' : 'show'}`} data-bs-parent="#accordionPanelsStayOpenExample">
                                    <div className="accordion-body">
                                        <div className='row m-0 p-0'>
                                            <div className='col-12 m-0 p-0'>
                                                <div className='account-accordion-box'>
                                                    <div className="accordion" id="accordionFlushExample">
                                                        {creditReportData?.creditAccountInformationDetails
                                                            && creditReportData?.creditAccountInformationDetails.length > 0
                                                            && accountDetails(creditReportData.creditAccountInformationDetails, creditReportData.personalInformation)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                }

                {/* {creditReportData?.personalInformation && <div className='container-lg container-fluid my-3' id='accountinfo'>
                    <div className="accordion" id="accordionPanelsStayTwoExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header mb-0">
                                <div className='d-flex flex-row justify-content-between py-1'>
                                    <div id='personalInformationCollapseButton'
                                        className={`accordion-button acc-info-text m-0 ${isCollapsedPersonalInfo ? 'collapsed' : ''}`} type="button" data-bs-target="#panelsStayOpen-collapseTwo" aria-controls="panelsStayOpen-collapseTwo"
                                        onClick={() => {
                                            toggleCollapsedPersonalInfo()
                                            // onPersonalInformationExpand()
                                        }}>
                                        Personal Information
                                    </div>
                                    <div className='pe-3 pt-1'>
                                        <img src={ICArrowUp} className={`arrow_icon ${isCollapsedPersonalInfo ? 'rotate-180' : ''}`}></img>
                                    </div>
                                </div>
                            </h2>
                            <div id="panelsStayOpen-collapseTwo" className={`accordion-collapse collapse ${isCollapsedPersonalInfo ? '' : 'show'}`} data-bs-parent="#accordionPanelsStayTwoExample">
                                <div className="accordion-body">
                                    <div className='row g-2 mt-2'>
                                        <div className='col-lg-6 pe-lg-2'>
                                            <div className='account-details-box p-0 m-0'>
                                                <p className='account-details-text  m-0 py-3'>Personal Information</p>
                                                <div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Name</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.name ?? "-"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Date of Birth</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.dateOfBirth ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Gender</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.gender ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Mobile Number</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.mobileNumber ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Email Address</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation.eamilAddress ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Occupation</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.employmentInfo?.occupation ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>PAN</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text text-break account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.pan ?? "N/A"}</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-6 ps-lg-2 pt-lg-0 pt-sm-0 pt-md-2 pt-2'>
                                            <div className='account-details-box p-0 m-0 '>
                                                <p className='account-details-text m-0 py-3'>Address</p>
                                                <div className='address-box'>
                                                    <div className='dividers'></div>
                                                    <div className='row p-0 m-0'>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-main-text account-details-main-text-new text-start'>Address</p>
                                                        </div>
                                                        <div className='col-6 p-0 m-0'>
                                                            <p className='account-details-sub-text account-details-sub-text-new text-end'>{creditReportData?.personalInformation?.address ?? "N/A"}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>} */}

            </div >


            {/* {getRefreshTokenStatus && getRefreshTokenStatus.tokenStatus && getRefreshTokenStatus.tokenStatus === "INVALID" &&  <div className='container-lg container-fluid'>
                <div className='row my-2 g-0 align-items-center'>
                    <div className='col-sm-12'>
                        <div className='boxes'>
                            <div className='row g-2 align-items-start'>
                                <div className='col-sm-8 col-md-8'>
                                    <div className='row text-start align-items-center align-items-sm-start'>
                                        <div className='col-12 d-flex'>
                                            <div className='mt-1 boost-score-image-box'>
                                                <img src={Score} className='' width='45' />
                                            </div>
                                            <div className='boost-gl-section'>
                                                <p className='boost-score-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0'>Get credit reports, statements and bills.</p>
                                                <p className='boost-score-sub-text ms-3 ms-sm-2 ms-md-3 mb-0 mt-1 mt-md-0'>Login with Google and Stay on top of your Credit Score</p>
                                            </div>
                                    </div>
                                    </div>
                                </div>  

                                <div className='col-sm-4 text-start col-md-4'>
                                    <div className='score-buttton-box box-score-credit-box'>
                                        <div className="abcRioButton abcRioButtonwhite gl-btn" onClick={() => { submit() }}>
                                            <div className="abcRioButtonContentWrapper">
                                                <div className="abcRioButtonIcon gl-padding">
                                                    <div className="abcRioButtonSvgImageWithFallback abcRioButtonIconImage abcRioButtonIconImage18 gl-svg-btn">
                                                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" className="abcRioButtonSvg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z">
                                                        </path>
                                                            <path fill="none" d="M0 0h48v48H0z"></path>
                                                        </g>
                                                        </svg>
                                                    </div>
                                                </div>
                                                <span className="abcRioButtonContents gl-btn-text">
                                                    <span id="gl-btn">Login with Google</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>} */}

            <BankStatment onClickCreditCall={() => { creditReportPull() }} setCreditReportData={setCreditReportData} setAnalysisReportData={insightsAnalysisReports} bureauDataGenerate={bureauDataGenerate} setMessage={setMessage} scrollToTop={props.scrollToTop} />
            {/* <Banking /> */}

            <Footer />
        </>
    );
}

export default ScoreCards;