import React from 'react';
import '../../../../App.css'
import { useEffect, useState } from 'react';
import { ReactDialogBox } from 'react-js-dialog-box'
import UploadSuccess from '../Upload-Success/upload-success';

function UploadProgress(props) {
    
   // const [isVerified, setIsVerified] = useState(false);
  //  const navigate = useNavigate();
    const [completed, setCompleted] = useState(0);
    const [showSuccessScreen,setShowSuccessScreen] = useState(false)
    const [dialogHeight, setDialogHeight] = useState('auto')

    useEffect(() => {
        if(props?.docProgress === 99){
          // props.setShowProgressScreen(false)
            setShowSuccessScreen(true)   
        }
    });

    const closeProgressDialog = () => {
       // props.setShowProgressScreen(false)
        setDialogHeight('auto')
    }

    return (
        <>
            <div className='container text-center'>
                <div className='upload-progress upload-ai-document'>
                  <p className="consent-progress-text">Uploading {props?.docType}</p>
                 <div className="progress mb-5">
                         <div className="progress-bar" role="progressbar" style={{width: props?.docProgress+"%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                     </div>
                 </div>
            </div>

             {showSuccessScreen && (
                <div className='upload-dialog'>
                    <ReactDialogBox
                        headerBackgroundColor='white'
                        headerTextColor='white'
                        headerHeight='0'
                        closeButtonColor='white'
                        bodyBackgroundColor='white'
                        bodyTextColor='black'
                        bodyHeight={dialogHeight}
                        borderRadius='8px'
                        closeBox={closeProgressDialog}
                    >
                    <UploadSuccess closeDialog={props.closeDialog} setShowProgressScreen={props.setShowProgressScreen} docType={props.docType}/>
                </ReactDialogBox></div>
            )}
        </>
    );
}
export default UploadProgress;